import React from "react"
import Layout from "../components/layout"
//import Banner from "../components/Banner/banner"
import Form from "../components/Form/form"
import { graphql } from "gatsby"
import Banner from "../components/Banner/banner2"

export const query = graphql`
  query Contactos($locale: String!) {
    childContactoJson: strapiContacto(locale: { eq: $locale }) {
      Banner {
        bannerTitle
        background {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, maxHeight: 294) {
              ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      intro
      titleLab
      address
      contactsTitle
      formAddress
      formPhone
      formButton
      email
      phone
    }
  }
`;

export default ({ pageContext: { locale, currentPath }, data }) => {
  const content = data.childContactoJson

  return (
    <Layout locale={locale} currentPath={currentPath}>
      <Banner
        banner={content.Banner.background.localFile}
        text={content.Banner.bannerTitle}
        layer={true}
        opacityValue={0.34}
        //logo={obcrei}
      />

      <div className="container" style={{ marginBottom: 100 }}>
        <div className="row justify-content-md-center">
          <div
            style={{ marginTop: 100 }}
            className="col-sm-10 col-xs-12 text-left color-primary">
            {content.intro}
          </div>

          <div className="col-sm-10 col-xs-12 text-left ">
            <h2 style={{ marginTop: 100, marginBottom: 45 }}>
              {content.contactsTitle}
            </h2>
          </div>
        </div>

        <div className="row justify-content-md-center">
          <div className="col-sm-10 col-xs-12 text-left ">
            <h4>Creative Industries Accelerator Lab</h4>
            <div className="color-primary text-default">{content.titleLab}</div>
          </div>
        </div>

        <div
          className="row justify-content-md-center"
          style={{ marginTop: 45 }}>
          <div className="col-sm-4 col-xs-12 text-left ">
            <div
              className="color-primary text-default bold"
              style={{ marginTop: 35 }}>
              {content.formAddress}
            </div>
            <div
              className="color-primary text-default"
              dangerouslySetInnerHTML={{
                __html: content.address,
              }}
            />

            <div
              className="color-primary text-default bold"
              style={{ marginTop: 45 }}>
              email
            </div>
            <div className="color-primary text-default">{content.email}</div>

            <div
              className="color-primary text-default bold"
              style={{ marginTop: 45 }}>
              {content.formPhone}
            </div>
            <div className="color-primary text-default">{content.phone}</div>
          </div>
          <div className="col-sm-6 col-xs-12 text-left ">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3004.212081281046!2d-8.618616284247004!3d41.15172337928657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2465027be07ebd%3A0xde238b38e579d63a!2sFLUP%20I%26D!5e0!3m2!1sen!2spt!4v1649258227746!5m2!1sen!2spt"
              width="459"
              height="312"
              frameBorder="0"
              allow="fullscreen"
            />
          </div>
        </div>

        <div className="row justify-content-md-center">
          <div className="col-sm-10 col-xs-12 text-left ">
            <h2 style={{ marginTop: 100, marginBottom: 45 }}>
              {content.formButton}
            </h2>
          </div>
        </div>

        <Form />
      </div>
    </Layout>
  )
}

// export const contacto = graphql`
//   query($locale: String) {
//     file(
//       name: { eq: $locale }
//       relativeDirectory: { eq: "textContent/contacto" }
//     ) {
//       childContactoJson {
//         bannerImage {
//           childImageSharp {
//             fluid(maxWidth: 1200, maxHeight: 294) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//         bannerText
//         intro
//         titleLab
//         address
//         contactsTitle
//         formAddress
//         formPhone
//         formButton
//         email
//         phone
//       }
//     }
//   }
// `
