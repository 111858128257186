import React from "react"
import "./form.scss"

const API_KEY =
  "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"

class Form extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      info: "",
    }
  }

  onChange = e => {
    // Handy generic onChange handler that relies on the `name` set
    // on an input to indicate which property in state to update.
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onSubmit = e => {
    e.preventDefault()
    // Usually, we would pass the final input values to a function that
    // would do something with the data like persist it to a database.
    // Using controlled inputs, we just need to pass that function the
    // values from state.

    fetch("http://creialab.monade.tech/sendmail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state),
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error("Something went wrong on api server!")
        }
      })
      .then(response => {
        this.setState({
          name: "",
          email: "",
          subject: "",
          message: "",
          info: "Mensagem enviada com sucesso!",
        })

        // setTimeout(() => this.setState({ info: "" }), 3000)
      })
      .catch(error => {
        console.error(error)
      })
  }

  render() {
    return (
      <div className="row justify-content-md-center" style={{ marginTop: 45 }}>
        <div className="col col-sm-10 col-xs-12 text-left ">
          <form className="form" onSubmit={this.onSubmit}>
            <div className="form--field">
              <label className="form--label color-primary text-legend">
                Nome
              </label>
              <input
                type="text"
                className="form-control form--input"
                name="name"
                onChange={this.onChange}
                onBlur={this.onChange}
                value={this.state.name}
                required
              />
            </div>
            <div className="form--field">
              <label className="form--label color-primary text-legend">
                Email
              </label>
              <input
                type="email"
                className="form-control form--input"
                name="email"
                onChange={this.onChange}
                onBlur={this.onChange}
                value={this.state.email}
                required
              />
            </div>
            <div className="form--field">
              <label className="form--label color-primary text-legend">
                Assunto
              </label>
              <input
                type="text"
                className="form-control form--input"
                name="subject"
                onChange={this.onChange}
                onBlur={this.onChange}
                value={this.state.subject}
                required
              />
            </div>
            <div className="form--field">
              <label className="form--label color-primary text-legend">
                Mensagem
              </label>
              <textarea
                type="text"
                className="form-control form--input"
                name="message"
                onChange={this.onChange}
                onBlur={this.onChange}
                value={this.state.message}
                required
              />
            </div>

            <div className="form--actions">
              <button
                type="submit"
                className="form--submit btn btn-creialab text--legend"
                style={{
                  color: "#ffffff",
                  minWidth: 146,
                  height: 38,
                  lineHeight: "25px",
                }}>
                Enviar
              </button>
              {this.state.info ? (
                <span
                  className="mensagem-ok alert alert-success"
                  style={{ marginLeft: 20, padding: "0.55rem 1.25rem" }}>
                  {this.state.info}
                </span>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default Form
